import React, { useState } from 'react';

import {
    Dialog,
    DialogContent,
    DialogActions,
    PaperProps,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import Suspend from 'Ui/Suspend';

import s from './assets/transitions.scss';
import CustomDialogTitle from './components/CustomDialogTitle';
import PaperComponent from './components/PaperComponent';
import backdropController from './models/backdropController';
import { IModalProps } from './types/IModal';
import { TPaperProps } from './types/TPaperProps';

const Transition = React.forwardRef((
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<HTMLDivElement>,
) => (
    <div
        ref={ref}
        className={`animated bounceInDown ${s['transition-wrapper']}`}
        onFocus={props.onFocus}
        tabIndex={props.tabIndex}
    >
        {props.children}
    </div>
));

function Modal({
    title,
    open,
    onClose,
    minWidth = 320,
    width = 900,
    height = 265,
    maxWidth,
    footer,
    draggableWindow = true,
    resizable = true,
    close: closeIcon = true,
    fullScreen: fullScreenIcon = true,
    children,
    animate = true,
    suspend = false,
    backdropType,
    callback,
    disableEnforceFocus = false,
}: IModalProps) {
    const [fullScreen, setFullScreen] = useState(false);

    const handleClickFullScreen = () => {
        setFullScreen(value => !value);
    };

    const handleOnClose = () => {
        if (!suspend) {
            onClose();
        }
    };

    const MyProps: PaperProps<'div', TPaperProps> = {
        square: true,
        fullScreen,
        minWidthModal: minWidth,
        widthModal: width,
        heightModal: height,
        draggableWindow,
        resizable,
        callback,
    };

    return (
        <Dialog
            onClose={handleOnClose}
            open={open}
            fullScreen
            maxWidth={maxWidth}
            aria-labelledby="dialog-title"
            PaperComponent={PaperComponent}
            PaperProps={MyProps}
            TransitionComponent={animate ? Transition : undefined}
            BackdropProps={backdropController(backdropType)}
            disableEnforceFocus={disableEnforceFocus}
        >
            <CustomDialogTitle
                id="dialog-title"
                draggableWindow={draggableWindow}
                onClose={closeIcon && onClose}
                onFullScreen={fullScreenIcon && handleClickFullScreen}
                fullScreen={fullScreen}
            >
                {title}
            </CustomDialogTitle>

            <DialogContent dividers>
                <Suspend suspend={suspend} />
                {children}
            </DialogContent>

            { footer && (
                <DialogActions>
                    {footer}
                </DialogActions>
            )}
        </Dialog>
    );
}

export default Modal;
