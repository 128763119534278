/* eslint-disable react/jsx-props-no-spreading */
import React, {
    SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import { useController, UseControllerReturn, useFormContext } from 'react-hook-form';

import {
    Autocomplete, IconButton, InputAdornment, TextField, Tooltip,
} from '@mui/material';

import { IContragentsOldStrucutreResponse } from 'CommonTypes/Contragents/ContragentsOldStrucutre';
import { IContragentSimpleSet } from 'CommonTypes/Contragents/ContragentsSimpleSet';

import { useGetContragentsSimpleSetQuery } from 'Endpoints/Contragents/contragents.api';

import connectorOldContragentsAddModel from 'fsrc/Sales/models/connectorOldContragentsAddModel.js';

function ContragentSelect() {
    const [newSelectedContragentId, setNewSelectedContragentId] = useState<number | null>(null);

    const { data: contragents = [], refetch } = useGetContragentsSimpleSetQuery();
    const { control } = useFormContext();

    const controller: UseControllerReturn = useController({
        name: 'contragent',
        control,
    });

    const onClickLinkHandle = useCallback(() => {
        const { id = 0 } = controller.field?.value || { id: 0 };
        window.open(`//${window.location.host}/contragents/${id}`, '_blank', 'noreferrer');
    }, [controller.field?.value]);

    const handleClickPlusButton = useCallback(() => {
        connectorOldContragentsAddModel(async (result: IContragentsOldStrucutreResponse) => {
            if (+result.id) {
                await refetch();
                setNewSelectedContragentId(result.id);
            }
        });
    }, [refetch]);

    const handleFieldChange = useCallback((
        e: SyntheticEvent,
        newValue: IContragentSimpleSet,
    ) => {
        if (typeof newValue === 'string' || newValue?.id === 0) {
            const name = typeof newValue === 'string' ? newValue : newValue.searchValue.replace('Добавить "', '').replace('"', '').trim();
            connectorOldContragentsAddModel(async (result: IContragentsOldStrucutreResponse) => {
                if (+result.id) {
                    await refetch();
                    setNewSelectedContragentId(result.id);
                }
            }, name, 1);
            return true;
        }
        return controller.field.onChange(newValue);
    }, [controller.field, refetch]);

    useEffect(() => {
        if (newSelectedContragentId) {
            const newContragent = contragents.find(contr => contr.id === newSelectedContragentId);
            if (newContragent) {
                controller.field.onChange(newContragent);
                if (newSelectedContragentId === newContragent.id) {
                    setNewSelectedContragentId(null);
                }
            }
        }
    }, [contragents, controller.field, newSelectedContragentId]);

    return (
        <Autocomplete
            size="small"
            options={contragents}
            value={controller.field.value}
            blurOnSelect
            handleHomeEndKeys={false}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Контрагент"
                    error={!!controller.fieldState.error}
                    helperText={controller.fieldState.error?.message}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {Boolean(controller.field.value) && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Открыть Контрагента">
                                            <IconButton onClick={onClickLinkHandle}>
                                                <i className="fa fa-external-link" />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )}
                                <InputAdornment position="end">
                                    <Tooltip title="Добавить Контрагента">
                                        <IconButton onClick={handleClickPlusButton}>
                                            <i className="fa fa-plus" />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            filterOptions={(options, { inputValue }) => {
                const filteredOptions = options.filter(
                    option => option.searchValue.toLowerCase().includes(inputValue.toLowerCase()),
                );

                if (inputValue !== '' && !filteredOptions.length) {
                    filteredOptions.push({
                        id: 0,
                        value: '',
                        searchValue: `Добавить "${inputValue}"`,
                    });
                }

                return filteredOptions;
            }}
            getOptionLabel={option => option.value}
            renderOption={(prop, option) => (
                <li {...prop} key={option.id} style={{ gap: '15px' }}>
                    {option.searchValue}
                </li>
            )}
            onChange={handleFieldChange}
            isOptionEqualToValue={(option, values) => {
                const optionId = option.id;
                const valueId = values.id;
                return optionId === valueId;
            }}
        />
    );
}

export default ContragentSelect;
