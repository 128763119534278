/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { FilterOptionsState } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { IUsers } from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

import { Autocomplete, AutocompleteLongList } from 'Ui/Form/Autocomplete';
import { DatePicker } from 'Ui/Form/DatePicker';

import classes from '../../assets/style.scss';
import Context from '../../models/context';
import { IDataForm } from '../../types/IDataForm';

function MainSection({ dataForm }: {dataForm: IDataForm}) {
    const { control } = useFormContext();
    const projectController = useController({ name: 'project', control });
    const {
        onChangeProjectByContact,
        onChangeUsersAndRolesByProject,
        onChangeUsersByRoles,
        onChangeRolesByUsers,
        onChangeContactsByDefault,
        onChangeUsersByDefault,
    } = useContext(Context);

    const filterUsers = (option: IUsers[], state: FilterOptionsState<IUsers>) => {
        const { inputValue } = state;
        const { value } = projectController.field;
        const userFilterOptions = value
            ? option.filter(user => (
                user.projectsId.includes(`${value.id}`) && user.value.toLowerCase().includes(inputValue.toLowerCase())
            ))
            : [];

        return userFilterOptions;
    };

    return (
        <div className={classes.modal_wrapper}>
            <AutocompleteLongList
                name="contact"
                label="Контакт"
                options={dataForm.contacts}
                callback={onChangeProjectByContact}
                disableClearable
                required
                avatar
                blurOnSelect
                extraButton={(
                    [
                        <Tooltip key={1} title="Выбрать себя Контактом">
                            <IconButton onClick={onChangeContactsByDefault}>
                                <i className="fa fa-user" />
                            </IconButton>
                        </Tooltip>,
                    ]
                )}
            />
            <AutocompleteLongList
                name="observers"
                label="Наблюдатели"
                multiple
                options={dataForm.contacts}
                blurOnSelect
            />
            <Grid item container rowSpacing={2}>
                <Grid
                    item
                    container
                    columnSpacing={2}
                    rowSpacing={2}
                >
                    <Grid item sx={{ flex: 9, minWidth: 228 }}>
                        <Autocomplete
                            name="serviceClasse"
                            label="Класс обслуживания"
                            options={dataForm.serviceClasses}
                            disableClearable
                            blurOnSelect
                        />
                    </Grid>
                    <Grid item sx={{ flex: 3, minWidth: 140 }}>
                        <DatePicker
                            name="deadline"
                            label="Срок"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    columnSpacing={2}
                    rowSpacing={2}
                >
                    <Grid item xs>
                        <Autocomplete
                            name="project"
                            label="Проект"
                            options={dataForm.projects}
                            sx={{ minWidth: 213 }}
                            callback={onChangeUsersAndRolesByProject}
                            disableClearable
                            required
                            blurOnSelect
                        />
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            name="taskTypes"
                            label="Бизнес процесс"
                            options={dataForm.taskTypes}
                            sx={{ minWidth: 213 }}
                            disableClearable
                            blurOnSelect
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        columnSpacing={2}
                        rowSpacing={2}
                    >
                        <Grid item xs>
                            <Autocomplete
                                name="assignee"
                                label="Исполнитель"
                                options={dataForm.users}
                                filterOptions={filterUsers}
                                sx={{ minWidth: 213 }}
                                avatar
                                blurOnSelect
                                callback={onChangeRolesByUsers}
                                extraButton={(
                                    <Tooltip title="Выбрать себя Исполнителем">
                                        <IconButton onClick={onChangeUsersByDefault}>
                                            <i className="fa fa-user" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            />
                        </Grid>
                        <Grid item xs>
                            <Autocomplete
                                name="role"
                                label="Роль"
                                options={dataForm.roles}
                                sx={{ minWidth: 213 }}
                                avatar
                                blurOnSelect
                                callback={onChangeUsersByRoles}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default MainSection;
