import React from 'react';

import { useGetTasksChecklistCountQuery } from 'Endpoints/Checklists/checklist.api';

import IChecklistTabCountProps from './types/IChecklistTabCountProps';

function ChecklistTabCount({ taskId }: IChecklistTabCountProps) {
    const { data = { count: 0 } } = useGetTasksChecklistCountQuery({ taskId });

    return (
        <>
            { data?.count
                ? (
                    <span>
                        &nbsp;(
                        {data?.count}
                        )
                    </span>
                )
                : null}
        </>
    );
}

export default ChecklistTabCount;
