/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useController, UseControllerReturn, useFormContext } from 'react-hook-form';

import {
    Autocomplete, TextField, SxProps, InputAdornment, FilterOptionsState,
} from '@mui/material';

import { Avatar } from './components/Avatar';
import ListboxComponent from './components/LongList/ListboxComponent';
import styledPopper from './components/LongList/styledPopper';

interface IOption {
    id: number | string;
    value: string;
}

interface INewValue {
    id: number;
    value: string;
    projectsId: string;
}

interface IProps<CallbackType> {
    name: string;
    label?: string;
    placeholder?: string;
    options: IOption[] | [];
    callback?: (newValue: INewValue & CallbackType) => void;
    sx?: SxProps;
    multiple?: boolean;
    required?: boolean;
    avatar?: boolean;
    limitTags?: number;
    filterOptions?: (option: any, state: FilterOptionsState<any>) => any[];
    disableClearable?: boolean;
    blurOnSelect?: boolean;
    extraButton?: React.ReactNode[];
    disabled?: boolean;
    listValue?: string;
    inputValue?: string;
}

function AutocompleteLongListRHF<TCallback>(props: IProps<TCallback>) {
    const {
        name, label, placeholder, options, callback, sx, multiple,
        required, avatar, filterOptions, disableClearable, blurOnSelect, extraButton, disabled,
        listValue, inputValue, limitTags = -1,
    } = props;

    const { control } = useFormContext();
    const controller: UseControllerReturn = useController({
        name,
        control,
        rules: {
            required: required
                ? {
                    value: true,
                    message: 'Поле не может быть пустым',
                }
                : undefined,
        },
    });

    return (
        <Autocomplete
            sx={sx}
            size="small"
            disableListWrap
            options={options}
            filterSelectedOptions
            value={controller.field.value}
            defaultValue={controller.field.value}
            multiple={multiple}
            disableClearable={disableClearable}
            blurOnSelect={blurOnSelect}
            limitTags={limitTags}
            filterOptions={filterOptions}
            PopperComponent={styledPopper}
            ListboxComponent={ListboxComponent}
            getOptionLabel={option => option[inputValue ?? 'value']}
            disabled={disabled}
            handleHomeEndKeys={false}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    error={!!controller.fieldState.error}
                    helperText={controller.fieldState.error?.message}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                {avatar && controller.field.value && (
                                    <InputAdornment position="start">
                                        <Avatar options={controller.field.value} />
                                    </InputAdornment>
                                )}
                                {params.InputProps.startAdornment}
                            </>),
                        endAdornment: (
                            <>
                                {!!extraButton?.length && (
                                    extraButton.map((row, index) => (
                                        <InputAdornment
                                            key={React.isValidElement(row) && row.key ? row.key : `extra-button-${index}`}
                                            position="end"
                                        >
                                            {row}
                                        </InputAdornment>
                                    ))
                                )}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(prop, option) => [{ ...prop, avatar }, { ...option, value: option[listValue ?? 'value'] }]}
            onChange={(e, newValue) => (callback
                ? callback(newValue)
                : controller.field.onChange(newValue))}
            isOptionEqualToValue={(option, values) => {
                const optionId = option.id;
                const valueId = values.id;
                return optionId === valueId;
            }}
        />
    );
}

export default AutocompleteLongListRHF;
